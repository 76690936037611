<template>
  <div class="recommend-template-container">
    <a-page-header style="margin-bottom: 10px" @back="goback">
      <span @click="goback" style="cursor: pointer;" slot="title">返回</span>
      <span slot="title" class="line"></span>
      <span
        slot="title"
      >瀑布流推荐配置（{{ query.type === 0 ? '标准版' : '精简版' }}）</span
      ></a-page-header
      >
    <InfoBoard :query="query" />
    <div class="recommend-template-content">
      <div class="recommend-template-content-header">
        <div class="tip">
          <h3>瀑布流广告源列表：</h3>
          <span
          >请按照下方推荐的瀑布流配置在各广告平台创建对应价格的广告位，再填入广告位列表中；如开启了自动创建广告源功能，则无需手动填写广告位ID。</span
          >
        </div>
        <div class="add-btn">
          <a-button type="primary" @click="addSource"><a-icon type="plus" />广告源</a-button>
        </div>
      </div>
      <div class="recommend-template-content-batch">
        <a-dropdown-button :trigger="['hover']" :disabled="selectedRowKeys.length === 0">
          批量操作
          <a-menu slot="overlay">
            <a-menu-item key="1" @click="handleBatchCopy"> <CopySvg style="margin-right: 8px" />批量复制 </a-menu-item>
            <a-menu-item key="2" @click="handleBatchDelete"> <a-icon type="delete" />批量删除 </a-menu-item>
          </a-menu>
        </a-dropdown-button>
      </div>
      <div class="table">
        <a-form-model ref="ruleForm" :model="formquery" v-bind="layout">
          <a-table
            size="middle"
            :rowKey="(record, index) => index"
            tableLayout="auto"
            :row-selection="{
              columnWidth: 50,
              selectedRowKeys: selectedRowKeys,
              onChange: onSelectChange,
              getCheckboxProps: (record) => {
                return {
                  props: {
                    disabled: record.disabled
                  }
                }
              }
            }"
            :data-source="formquery.sourceData"
            :pagination="false"
            :scroll="{ x: true, y: autoHeight }"
            bordered
          >
            <!-- 操作 -->
            <a-table-column align="left" title="操作" width="120px" :fixed="true">
              <template slot-scope="text, record, index">
                <div v-if="!record.disabled">
                  <a-button size="small" type="link" @click="handleCopyItem(record, index)">复制</a-button>
                  <a-button size="small" type="link" @click="handleDeleteItem(index)">删除</a-button>
                </div>
                <div v-else>已添加</div>
              </template>
            </a-table-column>
            <!-- 广告平台 -->
            <a-table-column align="left" title="广告平台" width="200px">
              <template slot-scope="text, record, index">
                <a-form-model-item
                  class="form_item"
                  :prop="`sourceData[${index}].platId`"
                  :rules="[
                    {
                      required: true,
                      message: '请选择广告平台',
                      trigger: 'change'
                    }
                  ]"
                >
                  <a-select
                    placeholder="请选择广告平台"
                    v-model="record.platId"
                    style="width: 150px;"
                    @change="changePlatId($event, record, index)"
                    :disabled="record.disabled"
                  >
                    <a-select-option v-for="(item) in platList" :key="item.id" :value="item.id">{{
                      item.name
                    }}</a-select-option>
                  </a-select>
                </a-form-model-item>
              </template>
            </a-table-column>
            <!-- 账户 -->
            <a-table-column align="left" title="账户名称" width="220px">
              <template slot-scope="text, record, index">
                <a-form-model-item
                  class="form_item"
                  :prop="`sourceData[${index}].platAccountId`"
                  :rules="[
                    {
                      required: true,
                      message: '请选择账户',
                      trigger: 'change'
                    }
                  ]"
                >
                  <a-select
                    placeholder="请选择账户"
                    v-model="record.platAccountId"
                    style="width: 180px"
                    @change="changeAccountId($event, record)"
                    :disabled="record.disabled"
                  >
                    <a-select-option
                      v-for="item in platsInfo[record.platId].platAccountList"
                      :key="item.id"
                      :value="+item.id"
                    >{{ item.name }}</a-select-option
                    >
                  </a-select>
                </a-form-model-item>
              </template>
            </a-table-column>
            <!-- 平台应用ID -->
            <a-table-column align="left" width="200px">
              <m-tips
                slot="title"
                :content="`在第三方广告平台创建应用后生成的应用ID。`"
                title="应用ID"
                :width="150"
                :showIcon="false"
              ></m-tips>
              <template slot-scope="text, record, index">
                <a-form-model-item
                  class="form_item"
                  :prop="`sourceData[${index}].platAppId`"
                  :rules="[
                    {
                      required: true,
                      message: '请输入应用ID',
                      trigger: 'blur'
                    }
                  ]"
                >
                  <a-input
                    :disabled="(record.hasPltAppId || record.disabled) && !!record.platAccountId"
                    placeholder="请输入应用ID"
                    v-model.trim="record.platAppId"
                    style="width: 150px"
                    @change="changePlatAppId($event, index)"
                  >
                  </a-input
                  ></a-form-model-item>
              </template>
            </a-table-column>
            <!-- 广告位ID -->
            <a-table-column align="left" width="200px">
              <m-tips
                slot="title"
                :content="`在第三方广告平台创建广告位后生成的广告位ID。`"
                title="广告位ID"
                :width="150"
                :showIcon="false"
              ></m-tips>
              <template slot-scope="text, record, index">
                <span
                  v-if="record.platAccountId && record.allowAutoCreate && record.isAutoCreate === '1'"
                  style="line-height: 32px;"
                  class="primary-text"
                >自动创建</span
                >
                <a-form-model-item
                  v-else
                  class="form_item"
                  :prop="`sourceData[${index}].platPlaceId`"
                  :rules="[
                    {
                      required: true,
                      message: '请输入广告位ID',
                      trigger: 'blur'
                    }
                  ]"
                >
                  <a-input
                    :disabled="record.disabled"
                    placeholder="请输入广告位ID"
                    v-model.trim="record.platPlaceId"
                    style="width: 150px"
                  >
                  </a-input>
                </a-form-model-item>
              </template>
            </a-table-column>
            <!-- 广告源名称 -->
            <a-table-column align="left" title="广告源名称" width="220px">
              <template slot-scope="text, record, index">
                <a-form-model-item
                  class="form_item"
                  :prop="`sourceData[${index}].name`"
                  :rules="[
                    {
                      required: true,
                      message: '请输入广告源名称',
                      trigger: 'blur'
                    }
                  ]"
                >
                  <a-input
                    :disabled="record.disabled"
                    placeholder="请输入广告源名称"
                    @change="handleChangeName(record)"
                    v-model.trim="record.name"
                    style="width: 190px"
                  >
                  </a-input
                  ></a-form-model-item>
              </template>
            </a-table-column>
            <!-- 价格类型 -->
            <a-table-column align="left" title="价格类型" width="150px">
              <template slot-scope="text, record, index">
                <a-form-model-item
                  class="form_item"
                  :prop="`sourceData[${index}].settleType`"
                  :rules="[
                    {
                      required: true,
                      message: '请选择价格类型',
                      trigger: 'change'
                    }
                  ]"
                >
                  <a-select
                    :disabled="record.disabled"
                    placeholder="请选择价格类型"
                    v-model="record.settleType"
                    style="width: 120px"
                    @change="changeSettleType($event, record)"
                  >
                    <a-select-option
                      v-for="(item, i) in platsInfo[record.platId].settleTypeListType === 1
                        ? settleTypeList1
                        : settleTypeList2"
                      :key="i"
                      :value="item.id"
                    >{{ item.name }}</a-select-option
                    >
                  </a-select>
                </a-form-model-item>
              </template>
            </a-table-column>
            <!-- 价格 -->
            <a-table-column align="left" width="150px">
              <m-tips
                slot="title"
                :content="`未开启自动创建广告源时，需在对应的广告平台设置目标价格eCPM，此处价格仅用于瀑布流排序和计算预估收益；设置穿山甲、百度目标价格，需要联系平台商务开通权限；当价格类型选择为服务端竞价时，此价格不会回传给广告平台，当广告平台出价低于竞价底价时，${title}将会过滤此出价结果，不返回广告填充。`"
                title="排序价格"
                :width="250"
                :showIcon="false"
              ></m-tips>
              <template slot-scope="text, record, index">
                <a-form-model-item
                  v-if="record.settleType === 0"
                  class="form_item"
                  :prop="`sourceData[${index}].bidfloor`"
                  :rules="[
                    {
                      required: true,
                      message: '请输入价格',
                      trigger: 'blur'
                    }
                  ]"
                >
                  <a-input
                    type="number"
                    placeholder="请输入价格"
                    v-model.number.trim="record.bidfloor"
                    style="width: 120px"
                    :disabled="record.disabled"
                    @change="handleChangeBidfloor(record)"
                  >
                    <span slot="addonAfter">￥</span>
                  </a-input>
                </a-form-model-item>
                <span v-else-if="record.settleType === 1" style="line-height: 32px" class="primary-text">服务端竞价</span>
                <span v-else-if="record.settleType === 2" style="line-height: 32px" class="primary-text">客户端竞价</span>
              </template>
            </a-table-column>
            <!-- 广告源状态 -->
            <a-table-column align="left" title="广告源状态" width="120px">
              <template slot-scope="text, record, index">
                <a-form-model-item class="form_item" :prop="`sourceData[${index}].sts`">
                  <a-switch
                    :disabled="record.disabled"
                    :checked="record.sts === 'A'"
                    @change="changeSts($event, index)"
                  />
                </a-form-model-item>
              </template>
            </a-table-column>
            <!-- 自动创建广告源 -->
            <a-table-column v-if="[1,3,4,5,6].includes(+query.positionId)" align="left" width="180px">
              <m-tips
                slot="title"
                :content="`${title}平台目前支持穿山甲、快手、优量汇三家平台的自动创建功能。开启自动创建广告源功能后，当您在${title}后台创建广告源时，${title}会自动在对应的第三方广告平台同步创建。`"
                title="自动创建广告源"
                :width="150"
                :showIcon="false"
              ></m-tips>
              <template slot-scope="text, record, index">
                <a-form-model-item class="form_item" :prop="`sourceData[${index}].isAutoCreate`">
                  <span style="line-height: 32px" v-if="![1, 2, 3].includes(record.platId)">--</span>
                  <span style="line-height: 32px" v-else-if="!record.platAccountId">请在左侧选择账户</span>
                  <span
                    style="line-height: 32px"
                    v-else-if="!record.allowAutoCreate"
                  >去<span
                    @click="toAllowAutoCreate(record, index)"
                    style="cursor: pointer"
                    class="primary-text"
                  >授权开启</span
                  ></span
                  >
                  <a-switch
                    :disabled="record.disabled"
                    v-else
                    :checked="record.isAutoCreate === '1'"
                    @change="changeIsAutoCreate($event, index)"
                  />
                </a-form-model-item>
              </template>
            </a-table-column>
            <!-- 素材类型 -->
            <a-table-column v-if="showAutoCreateCol" align="left" title="素材类型" width="220px">
              <template slot-scope="text, record, index">
                <a-form-model-item
                  v-if="
                    record.allowAutoCreate &&
                      +record.isAutoCreate === 1 &&
                      (((+query.positionId === 6 || +query.positionId === 4) && record.platId === 3) ||
                      (+query.positionId === 6 && record.platId === 1))
                  "
                  class="form_item"
                  :prop="`sourceData[${index}].adCrtTypeList`"
                  :rules="[
                    {
                      required: true,
                      message: '请选择素材类型',
                      trigger: 'change'
                    }
                  ]"
                >
                  <m-select-more
                    v-if="(+query.positionId === 6 || +query.positionId === 4) && record.platId === 3"
                    style="width: 190px"
                    v-model="record.adCrtTypeList"
                    :disabled="record.disabled"
                    :allData="[
                      { name: '竖版视频', id: '1' },
                      { name: '横版视频', id: '2' },
                      { name: '竖版图片', id: '5' },
                      { name: '横版图片', id: '6' }
                    ]"
                    :searchById="false"
                    :showLabel="false"
                    :hasIcon="false"
                    :showId="false"
                    width="450px"
                    label="素材类型"
                  />
                  <a-select
                    v-else-if="+query.positionId === 6 && record.platId === 1 && record.isAutoCreate === '1'"
                    placeholder="请选择素材类型"
                    v-model="record.adCrtTypeList[0]"
                    style="width: 200px"
                    :disabled="record.disabled"
                  >
                    <a-select-option value="1">仅图片</a-select-option>
                    <a-select-option value="2">仅视频</a-select-option>
                    <a-select-option value="3">图片+视频</a-select-option>
                  </a-select>
                </a-form-model-item>
                <div v-else>--</div>
              </template>
            </a-table-column>
            <!-- 广告样式 -->
            <a-table-column v-if="showAutoCreateCol" align="left" title="广告样式" width="220px">
              <template slot-scope="text, record, index">
                <a-form-model-item
                  class="form_item"
                  v-if="record.allowAutoCreate && +record.platId === 1 && +query.positionId === 4 && record.isAutoCreate === '1'"
                  :prop="`sourceData[${index}].templateIdList`"
                  :rules="[
                    {
                      required: true,
                      message: '请选择广告样式',
                      trigger: 'change'
                    }
                  ]"
                >
                  <m-select-more
                    style="width: 190px"
                    :disabled="record.disabled"
                    v-model="record.templateIdList"
                    :allData="[
                      { id: '1', name: '⼤图' },
                      { id: '2', name: '组图' },
                      { id: '3', name: '单图' },
                      { id: '4', name: '视频' },
                      { id: '5', name: '竖版视频' },
                      { id: '6', name: '竖图' }
                    ]"
                    :searchById="false"
                    :showLabel="false"
                    :hasIcon="false"
                    :showId="false"
                    width="450px"
                    label="广告样式"
                  />
                </a-form-model-item>
                <div v-else>--</div>
              </template>
            </a-table-column>
            <!-- 模版样式 -->
            <a-table-column v-if="showAutoCreateCol" align="left" title="模版样式" width="220px">
              <template slot-scope="text, record, index">
                <a-form-model-item
                  class="form_item"
                  v-if="showTemplateIds(record)"
                  :prop="`sourceData[${index}].templateIdList`"
                  :rules="[
                    {
                      required: true,
                      message: '请选择模版样式',
                      trigger: 'change'
                    }
                  ]"
                >
                  <m-select-more
                    style="width: 190px"
                    :disabled="record.disabled"
                    v-model="record.templateIdList"
                    :allData="getTemplateList(record)"
                    :searchById="false"
                    :showLabel="false"
                    :hasIcon="false"
                    :showId="false"
                    width="450px"
                    label="模版样式"
                  />
                </a-form-model-item>
                <div v-else>--</div>
              </template>
            </a-table-column>
            <!-- 失败原因 -->
            <a-table-column align="center" v-if="hasError" width="300px" fixed="right">
              <m-tips
                slot="title"
                :content="`此处显示瀑布流广告源创建失败的原因，请按提示原因修改，重新提交后则更新失败原因。`"
                title="失败原因"
                :width="150"
                :showIcon="false"
              ></m-tips>
              <template slot-scope="text, record">
                <span style="color: red">
                  {{ record.msg }}
                </span>
              </template>
            </a-table-column>
          </a-table>
        </a-form-model>
      </div>
    </div>
    <div class="recommend-template-footer">
      <a-button @click="goback">取消</a-button>
      <a-button type="primary" @click="handleSubmit">保存并覆盖原配置</a-button>
    </div>
    <AddAccountModal
      v-if="addAccountVisible"
      :visible="addAccountVisible"
      :platInfo="currentPlatInfo"
      :accountInfo="accountInfo"
      :isAddAcount="isAddAcount"
      @modalCancel="addAccountVisible = false"
      :query="query"
      @addNewAccount="getPlatAccounts"
      @editAccount="editAccount"
    />
  </div>
</template>

<script>
// - 广告样式
//   - 穿山甲 原生自渲染

// - 模版样式

//   - 优量汇 原生自渲染
//   - 穿山甲 原生模版
//   - 优量汇 原生模版
//   - 快手 原生模版

// - 素材类型
//   - 快手 原生自渲染
//   - 穿山甲 原生模版
//   - 快手 原生模版

import InfoBoard from './InfoBoard'
import { getPlatAccounts, getRecommendTemplate, batchBindPlat, batchAddSource, updateArgs } from '@/api/aggregate'
import AddAccountModal from '../Config/components/addAccountModal'
import { mapState } from 'vuex'
import CopySvg from '@/assets/icons/copy.svg?inline'
import mixGetList from '@/mixins/getList'

export default {
  name: 'RecommendTemplate',
  components: { InfoBoard, AddAccountModal, CopySvg },
  mixins: [mixGetList],
  data () {
    return {
      // table自动高度
      autoHeight: window.innerHeight - 300,
      // 账户弹窗是否显示
      addAccountVisible: false,
      // 是否是新增账户
      isAddAcount: false,
      // 当前点击的广告源所对应的广告平台信息
      currentPlatInfo: {
        name: '',
        id: 1,
        accessType: undefined
      },
      // 当前点击的广告源索引
      currentSourceIndex: 0,
      // 当前点击的广告源所对应的账户信息
      accountInfo: {},
      layout: {
        labelCol: { span: 2 },
        wrapperCol: { span: 20 }
      },
      query: {
        appId: undefined,
        placeId: undefined,
        groupId: undefined,
        testId: undefined,
        positionId: undefined,
        type: undefined
      },
      formquery: {
        sourceData: []
      },
      platsInfo: {
        1: {
          // 平台名
          platName: '穿山甲',
          // 账户列表
          platAccountList: [],
          // 价格类型列表
          settleTypeListType: 1,
          // SDK
          accessType: '0'
        },
        2: {
          platName: '优量汇',
          platAccountList: [],
          settleTypeListType: 2,
          accessType: '0'
        },
        3: {
          platName: '快手',
          platAccountList: [],
          settleTypeListType: 2,
          accessType: '0'
        },
        6: {
          platName: '百度',
          platAccountList: [],
          settleTypeListType: 2,
          accessType: '0'
        }
      },
      settleTypeList1: [
        {
          name: '固价',
          id: 0
        }
      ],
      settleTypeList2: [
        {
          name: '固价',
          id: 0
        },
        {
          name: '服务端竞价',
          id: 1
        },
        {
          name: '客户端竞价',
          id: 2
        }
      ],
      // 批量数组（索引）
      selectedRowKeys: [],
      // 添加广告源的模版
      sourceTemp: {},
      hasError: false,
      isCompleted: false
    }
  },
  async created () {
    this.query = {
      ...this.$route.query
    }
    await this.getPlatList()
    this.platList = this.platList.filter((item) => item.accessType === '0')
  },
  computed: {
    showAutoCreateCol () {
      return [4, 6].includes(+this.query.positionId)
    },
    ...mapState({
      // 角色
      role: (state) => state.user.roles[0],
      // 平台名
      title: (state) => state.autoweb.title,
      allRoutes: (state) => state.permission.allRoutes,
      origin: (state) => state.autoweb.origin
    })
  },
  async mounted () {
    await this.getPlatAccounts()
    await this.getRecommendTemplate()
  },
  beforeRouteLeave (to, form, next) {
    if (this.isCompleted) {
      next()
    } else {
      this.$confirm({
        title: '提示',
        maskClosable: true,
        content: () => {
          return (
            <div class='method-modal' style="height:100px;display:flex;padding:30px">
              <div>
                <a-icon
                  style="color: #fa8b15;font-size: 22px;margin-right: 20px;line-height:40px"
                  type="exclamation-circle"
                />
              </div>
              <span>确定离开当前页面吗？当前页面广告源信息未保存，离开后将会丢失已填写信息。</span>
            </div>
          )
        },
        onOk: () => {
          next()
        },
        icon: () => {
          return ''
        },
        class: 'method-modal-wrapper',
        width: 600
      })
    }
  },
  methods: {
    goback () {
      this.$router.push({
        name: 'config',
        query: {
          appId: this.query.appId,
          placeId: this.query.placeId
        }
      })
    },
    // 获取并初始化广告源列表
    async getRecommendTemplate () {
      const { data = [] } = await getRecommendTemplate({
        positionId: this.query.positionId,
        appId: this.query.appId,
        type: this.query.type
      })
      this.formquery.sourceData = []
      data.forEach((item, index) => {
        // if (index > 3) return
        this.formquery.sourceData.push(item)
        const currentAccount = this.platsInfo[+item.platId].platAccountList[0]
        // 广告源基础信息
        this.formquery.sourceData[index].position = this.query.positionId
        this.formquery.sourceData[index].appId = this.query.appId
        this.formquery.sourceData[index].placeId = this.query.placeId
        this.formquery.sourceData[index].groupId = this.query.groupId
        this.formquery.sourceData[index].testId = this.query.testId
        // 每个广告源唯一标识（批量绑定使用）
        this.formquery.sourceData[index].key = '' + Math.random()
        // 账户
        this.$set(this.formquery.sourceData[index], 'platAccountId', currentAccount.id)
        // 应用ID
        this.$set(this.formquery.sourceData[index], 'platAppId', currentAccount.platAppId)
        // 账户是否绑定过应用ID
        this.$set(this.formquery.sourceData[index], 'hasPltAppId', !!currentAccount.platAppId)
        // 账户是否开启自动创建广告源的权限
        this.$set(this.formquery.sourceData[index], 'allowAutoCreate', +currentAccount.isAutoCreate === 1)
        // 广告源名称模版
        this.$set(this.formquery.sourceData[index], 'nameTemplete', item.name)
        // 广告源名称是否修改过
        this.$set(this.formquery.sourceData[index], 'hasChangeName', false)
        const suffix = item.settleType === 0 ? (typeof (item.bidfloor) === 'number' ? item.bidfloor : '') : item.settleType === 1 ? '服务端竞价' : '客户端竞价'
        const name = item.name + '-' + suffix
        // 广告源名称
        this.$set(this.formquery.sourceData[index], 'name', name)
        // 广告源ID（广告位ID）
        this.$set(this.formquery.sourceData[index], 'platPlaceId', undefined)
        // 竞价类型
        this.$set(this.formquery.sourceData[index], 'settleType', item.settleType)
        // 价格
        this.$set(this.formquery.sourceData[index], 'bidfloor', item.bidfloor)
        // 广告源状态
        this.$set(this.formquery.sourceData[index], 'sts', 'A')
        // 是否开启自动创建广告源
        this.$set(this.formquery.sourceData[index], 'isAutoCreate', +currentAccount.isAutoCreate === 1 ? '1' : +currentAccount.isAutoCreate === 0 ? '0' : '0')
        // 广告样式
        let templateIdList = []
        if (+item.platId === 1 && +this.query.positionId === 4 && item.isAutoCreate === '1') {
          templateIdList = ['1', '2', '3', '4', '5', '6']
        } else {
          templateIdList = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11']
        }
        this.$set(this.formquery.sourceData[index], 'templateIdList', templateIdList)
        // 素材类型
        let adCrtTypeList = []
        if (
          (((+this.query.positionId === 6 || +this.query.positionId === 4) && item.platId === 3) ||
            (+this.query.positionId === 4 && item.platId === 1)) &&
          item.isAutoCreate === '1'
        ) {
          adCrtTypeList = ['1', '2', '5', '6']
        } else if (+this.query.positionId === 6 && item.platId === 1 && item.isAutoCreate === '1') {
          adCrtTypeList = ['1']
        }
        this.$set(this.formquery.sourceData[index], 'adCrtTypeList', adCrtTypeList)
      })
      // 得到新增广告源模版
      if (this.formquery.sourceData[0]) {
        for (const key in this.formquery.sourceData[0]) {
          this.$set(this.sourceTemp, key, undefined)
        }
      }
    },
    // 获取所有广告平台的账户列表
    async getPlatAccounts () {
      for (const key in this.platsInfo) {
        const { data = [] } = await getPlatAccounts({
          appId: this.query.appId,
          platId: key
        })
        // 更行账号列表
        this.platsInfo[key].platAccountList = data
      }
    },
    async getOneplatAccounts (id) {
      const { data = [] } = await getPlatAccounts({
        appId: this.query.appId,
        platId: id
      })
      this.platsInfo[id].platAccountList = data
    },
    // 账户修改成功回调
    async editAccount () {
      // 得到修改的广告源
      const currentSource = this.formquery.sourceData[this.currentSourceIndex]
      // 更新所对应的广告平台下的账户列表
      const { data = [] } = await getPlatAccounts({
        appId: this.query.appId,
        platId: currentSource.platId
      })
      this.platsInfo[currentSource.platId].platAccountList = data
      // 该广告源绑定的账户
      const currentAccount = this.platsInfo[currentSource.platId].platAccountList.find(
        (item) => item.id === currentSource.platAccountId
      )
      // 判断该账号是否允许自动创建广告源
      currentSource.allowAutoCreate = currentAccount.isAutoCreate === '1'
    },
    // 单个添加
    addSource () {
      // 通过添加模版添加
      const newSource = JSON.parse(JSON.stringify(this.sourceTemp))
      for (const key in this.sourceTemp) {
        this.$set(newSource, key, this.sourceTemp[key])
      }
      // 默认选择穿山甲
      newSource.platId = 1
      // 默认固价
      newSource.settleType = 0
      // 广告样式  模版样式
      newSource.getTemplateList = []
      // 素材类型
      newSource.adCrtTypeList = []
      newSource.sts = 'A'
      newSource.isAutoCreate = '1'
      newSource.hasChangeName = true
      newSource.nameTemplete = ''
      newSource.platAccountId = undefined
      newSource.platAppId = undefined
      newSource.key = '' + Math.random()
      this.formquery.sourceData.push(newSource)
      this.$message.success('添加成功！')
    },
    // 批量复制广告源
    handleBatchCopy () {
      const copyArr = []
      this.selectedRowKeys.forEach((index) => {
        copyArr.push(JSON.parse(JSON.stringify(this.formquery.sourceData[index])))
        copyArr[index].key = '' + Math.random()
      })
      this.formquery.sourceData = [...this.formquery.sourceData, ...copyArr]
      this.selectedRowKeys = []
      // 复制成功
      this.$message.success('复制成功')
    },
    // 批量删除广告源
    handleBatchDelete () {
      this.$confirm({
        title: '提示',
        maskClosable: true,
        content: () => {
          return (
            <div class='method-modal' style="height:100px;display:flex;padding:30px">
              <div>
                <a-icon
                  style="color: #fa8b15;font-size: 22px;margin-right: 20px;line-height:30px"
                  type="exclamation-circle"
                />
              </div>
              <span>确定删除已选中的广告源？</span>
            </div>
          )
        },
        onOk: () => {
          this.formquery.sourceData = this.formquery.sourceData.filter(
            (item, index) => !this.selectedRowKeys.includes(index)
          )
          this.selectedRowKeys = []
          this.$message.success('删除成功！')
        },
        icon: () => {
          return ('')
        },
        class: 'method-modal-wrapper'
      })
    },
    onSelectChange (selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    // 单个复制
    handleCopyItem (record, index) {
      const copyItem = {}
      for (const key in record) {
        this.$set(copyItem, key, record[key] !== undefined ? JSON.parse(JSON.stringify(record[key])) : undefined)
      }
      copyItem.key = '' + Math.random()
      this.formquery.sourceData.splice(index + 1, 0, copyItem)
      this.selectedRowKeys = []
      this.$message.success('复制成功！')
    },
    // 单个删除
    handleDeleteItem (index) {
      this.formquery.sourceData.splice(index, 1)
      this.selectedRowKeys = []
    },
    // 广告平台修改回调
    async changePlatId (e, record, index) {
      // 判断该广告平台之前是否加载过
      if (!this.platsInfo[e]) {
        const currentPlat = this.platList.find((item) => item.id === e)
        this.$set(this.platsInfo, e, {
          // 平台名
          platName: '',
          // 该平台下的账户
          platAccountList: [],
          // 该平台的价格类型列表
          settleTypeListType: currentPlat.settleType,
          // SDK or API
          accessType: currentPlat.accessType
        })
        // 获取单个广告平台的账户列表
        this.getOneplatAccounts(e)
      }
      record.platAccountId = undefined
      record.platAppId = undefined
      record.hasPltAppId = false
      record.disabled = false
      record.allowAutoCreate = false
      record.getTemplateList = []
      record.adCrtTypeList = []
      record.settleType = undefined
      this.$refs.ruleForm.clearValidate()
    },
    // 账户修改回调
    changeAccountId (e, record) {
      const currentAccount = this.platsInfo[+record.platId].platAccountList.find((item) => +item.id === +e)
      // 平台应用ID
      currentAccount.platAppId && (record.platAppId = currentAccount.platAppId)
      !currentAccount.platAppId && (record.platAppId = undefined)
      // 账号是否绑定平台应用ID
      currentAccount.platAppId && (record.hasPltAppId = true)
      !currentAccount.platAppId && (record.hasPltAppId = false)
      // 账号是否拥有自动创建广告源权限
      currentAccount.isAutoCreate === '1' && (record.allowAutoCreate = true)
      currentAccount.isAutoCreate === '0' && (record.allowAutoCreate = false)
      this.$refs.ruleForm.clearValidate()
    },
    // 应用ID字母限制
    changePlatAppId (e, index) {
      this.formquery.sourceData[index].platAppId = e.target.value.replace(/[a-z+A-Z+/]/g, '')
    },
    // 修改名称后改变hasChangeName标志
    handleChangeName (record) {
      record.hasChangeName = true
    },
    // 修改价格类型对广告源名称进行处理
    changeSettleType (e, record) {
      if (record.hasChangeName) return
      const suffix = +e === 0 ? (typeof (record.bidfloor) === 'number' ? record.bidfloor : '') : +e === 1 ? '服务端竞价' : '客户端竞价'
      record.name = record.nameTemplete + '-' + suffix
    },
    // 价格处理，价格修改对于广告源名称进行处理
    handleChangeBidfloor (record) {
      if (record.bidfloor < 0.01 && record.bidfloor !== 0) {
        record.bidfloor = 0.01
      }
      !record.hasChangeName && (record.name = record.nameTemplete + '-' + (typeof (record.bidfloor) === 'number' ? record.bidfloor : ''))
    },
    // 修改广告源状态
    changeSts (e, index) {
      this.formquery.sourceData[index].sts = e ? 'A' : 'S'
    },
    // 修改广告源是否允许自动创建
    changeIsAutoCreate (e, index) {
      this.formquery.sourceData[index].isAutoCreate = e ? '1' : '0'
    },
    toAllowAutoCreate (record, index) {
      this.currentSourceIndex = index
      this.getPlatAndAccountInfo(record)
      this.isAddAcount = false
      this.addAccountVisible = true
    },
    // 获取当前点击行的账户信息和平台信息
    getPlatAndAccountInfo (record) {
      const currentPlat = this.platsInfo[record.platId]
      this.accountInfo = currentPlat.platAccountList.find((item) => item.id === record.platAccountId)
      this.currentPlatInfo = {
        name: currentPlat.platName,
        accessType: currentPlat.accessType,
        id: record.platId
      }
    },
    // 是否显示模版样式
    showTemplateIds (record) {
      if (record.isAutoCreate === '0' || !record.allowAutoCreate) return false
      const position = +this.query.positionId
      const platId = +record.platId
      if (position === 4 && platId === 2) {
        // 原生模版 优量汇
        return true
      } else if ((platId === 1 || platId === 2 || platId === 3) && position === 6) {
        // 原生自渲染 （穿山甲 优量汇  快手）
        return true
      }
      return false
    },
    // 模版样式
    getTemplateList (record) {
      // 原生模版 优量汇
      if (+this.query.positionId === 4 && +record.platId === 2) {
        return [
          { id: '1', name: '上图下文（支持视频）' },
          { id: '2', name: '上文下图（支持视频）' },
          { id: '3', name: '左图右文' },
          { id: '4', name: '左文右图' },
          { id: '5', name: '双图双文（支持视频）' },
          { id: '6', name: '纯图竖版' },
          { id: '7', name: '纯图横版（支持视频）' },
          { id: '8', name: '三小图双文' },
          { id: '9', name: '上文下图-文字浮层' },
          { id: '10', name: '上图下文-文字浮层' },
          { id: '11', name: '单图单文-文字浮层' }
        ]
      } else if (+this.query.positionId === 6 && record.platId === 1) {
        if (record.adCrtTypeList.length === 0 || +record.adCrtTypeList[0] !== 2) {
          return [
            { id: '1', name: '上⽂下图' },
            { id: '2', name: '上图下⽂' },
            { id: '3', name: '⽂字浮层' },
            { id: '4', name: '竖版' },
            { id: '5', name: '左图右⽂' },
            { id: '6', name: '左⽂右图' },
            { id: '7', name: '三图' }
          ]
        } else if (+record.adCrtTypeList[0] === 2) {
          // 穿山甲 原生自渲染
          return [
            { id: '1', name: '上⽂下图' },
            { id: '2', name: '上图下⽂' },
            { id: '3', name: '⽂字浮层' },
            { id: '4', name: '竖版' }
          ]
        }
      } else if (+this.query.positionId === 6 && record.platId === 2) {
        // 原生自渲染  优量汇
        return [
          { id: '1', name: '上图下文（支持视频）' },
          { id: '2', name: '上文下图（支持视频）' },
          { id: '3', name: '左图右文' },
          { id: '4', name: '左文右图' },
          { id: '5', name: '双图双文（支持视频）' },
          { id: '6', name: '纯图竖版' },
          { id: '7', name: '纯图横版（支持视频）' },
          { id: '8', name: '三小图双文' },
          { id: '9', name: '上文下图-文字浮层' },
          { id: '10', name: '上图下文-文字浮层' },
          { id: '11', name: '单图单文-文字浮层' }
        ]
      } else if (+this.query.positionId === 6 && record.platId === 3) {
       // 原生模版 快手 1:竖版视频 2:横版视频 5: 竖版图片 6: 横版图片
        const obj3 = [
          { id: '1', name: '大图(支持横版图片/视频)' },
          { id: '2', name: '左文右图(支持横版图片)' },
          { id: '3', name: '左图右文(支持横版图片)' },
          { id: '4', name: '上文下图(支持横版图片/视频)' },
          { id: '5', name: '上图下文(支持横版图片/视频)' },
          { id: '14', name: '三图(支持横版图片)' },
          { id: '15', name: '三图组合(支持横版图片)' },
          { id: '16', name: '橱窗(支持横版图片)' }
        ]
        const obj = [
          { id: '17', name: '上文下图(支持竖版图片/视频)' },
          { id: '18', name: '上图下文(支持竖版图片/视频)' },
          { id: '19', name: '大图（支持竖版图片/视频' }
        ]
        const obj1 = [
          { id: '1', name: '大图(支持横版图片/视频)' },
          { id: '4', name: '上文下图(支持横版图片/视频)' },
          { id: '5', name: '上图下文(支持横版图片/视频)' }
        ]
        const list = {
          1: obj,
          5: obj,
          2: obj1,
          6: obj3
        }
        let arr = []
        record.adCrtTypeList.forEach((item) => {
          arr = [...arr, ...list[item]]
        })
        return [...new Map(arr.map((item) => [item.id, item])).values()]
      }
      return []
    },
    handleSubmit () {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          const allBind = await this.batchBindPlatAppId()
          if (!allBind) return
          const allAdd = await this.batchAddSource()
          if (!allAdd) return
          // 高级设置
          const concurrentNum =
            this.formquery.sourceData.length % 3 === 0
              ? this.formquery.sourceData.length / 3
              : parseInt(this.formquery.sourceData.length / 3) + 1
          const updateArgsQuery = {
            timeout: 3000,
            allTime: 10000,
            apiTimeout: 3000,
            clientTimeout: 3000,
            requestInterval: 1000,
            testId: this.query.testId,
            groupId: this.query.groupId,
            placeId: this.query.placeId,
            concurrentNum
          }
          const result = await updateArgs(updateArgsQuery)
          if (result.code === 200) {
            this.isCompleted = true
            this.$message.success('添加成功！')
            this.goback()
          }
        } else {
          this.$message.warning('请完成当前表单填写！')
        }
      })
    },
    async batchAddSource () {
      const sourceList = this.formquery.sourceData.filter((item) => !item.disabled)
      if (sourceList.length === 0) {
        return true
      }
      const resp = await batchAddSource({
        sourceData: sourceList
      })
      if (resp.code === 200) {
        return true
      } else {
        const msgList = resp.data || []
        msgList.forEach((item) => {
          const errorIndex = this.formquery.sourceData.findIndex((child, index) => +index === +item.index)
          this.$set(this.formquery.sourceData[errorIndex], 'msg', item.msg === 'success' ? '' : item.msg)
          this.$set(this.formquery.sourceData[errorIndex], 'disabled', item.msg === 'success')
        })
        const successSource = this.formquery.sourceData.filter((success) => success.disabled)
        const failSource = this.formquery.sourceData.filter((success) => !success.disabled)
        this.formquery.sourceData = [...failSource, ...successSource]
        this.hasError = true
        return false
      }
    },
    async batchBindPlatAppId () {
      const appPlatLinkList = this.formquery.sourceData
        .filter((item) => !item.disabled && !item.hasPltAppId)
        .map((item) => {
          return {
            appId: item.appId,
            appKey: '',
            platId: item.platId,
            platAccountId: item.platAccountId,
            platAppId: item.platAppId,
            index: item.key
          }
        })
      if (appPlatLinkList.length === 0) return true
      const bindquery = {
        appPlatLinkList
      }
      const resp = await batchBindPlat(bindquery)
      if (resp.code === 200 || resp.code === 201) {
        await this.getPlatAccounts()
        this.formquery.sourceData.forEach((source) => {
          const currentAccount = this.platsInfo[source.platId].platAccountList.find(
            (account) => account.id === source.platAccountId
          )
          if (currentAccount.platAppId === source.platAppId) {
            source.hasPltAppId = true
          }
        })
      }
      if (resp.code === 200) {
        return true
      } else {
        const msgList = resp.data
        this.setBindMsg(msgList)
        this.hasError = true
        return false
      }
    },
    setBindMsg (msgList) {
      msgList.forEach((item) => {
        const errorIndex = this.formquery.sourceData.findIndex((child) => child.key === item.index)
        this.$set(this.formquery.sourceData[errorIndex], 'msg', item.msg === 'success' ? '' : item.msg)
      })
    }
  }
}
</script>

<style lang="less" scoped>
@paddBottom: 60px;
.recommend-template-container {
  margin: -55px 0 10px;
  padding-bottom: @paddBottom;
  ::v-deep {
    .ant-page-header {
      background: #fff;
      border-radius: 5px;
      padding: 7px 24px;
      .ant-page-header-heading {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      span {
        font-size: 14px;
      }
      .line {
        margin: 0 10px;
        background-color: gray;
        width: 2px;
        height: 12px;
        display: inline-block;
      }
    }
  }
  .recommend-template-content {
    min-height: 500px;
    border-radius: 10px;
    background: #fff;
    padding: 20px ;
    margin: 0 10px;
    .recommend-template-content-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      .tip {
        h3 {
          font-size: 16px;
          line-height: 30px;
        }
        span {
          font-size: 14px;
          line-height: 20px;
          font-weight: 400;
        }
      }
    }
    .recommend-template-content-batch {
      padding: 0 0 20px 5px;
    }
  }
  .recommend-template-footer {
    z-index: 99;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: @paddBottom;
    overflow: hidden;
    transition: all 0.8s;
    background: #fff;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    box-shadow: -3px -3px 10px 0px #ddd;
    padding: 0 10px;
    button {
      margin: 0 10px;
    }
  }
}
::v-deep.recommend-template-container {
  .ant-page-header-back {
    font-size: 14px;
  }
  .form_item {
    display: flex;
    .ant-form-explain {
      width: 120px;
    }
  }
  .ant-table-content {
    .ant-table-scroll .ant-table-body .ant-table-fixed .ant-table-tbody {
      tr {
        height: 70px;
        td {
          padding: 0 12px;
          .ant-form-item {
            display: flex;
            margin-bottom: 0;
            height: 40px;
            margin-top: 5px;
            .ant-form-item-control {
              line-height: 20px;
            }
            .ant-form-explain,
            .ant-form-extra {
              min-height: 16px;
              line-height: 16px;
              font-size: 12px;
            }
            .ant-select {
              padding: 0;
            }
            .ant-switch {
              margin-top: 8px;
            }
          }
        }
      }
    }
  }
  .ant-btn-group.ant-dropdown-button {
    .ant-btn {
      border-radius: 5px 0 0 5px;
      &.ant-dropdown-trigger {
        border-radius: 0 5px 5px 0;
      }
    }
  }
}
</style>

<style lang="less">
.method-modal-wrapper{
  &.ant-modal-confirm.ant-modal-confirm-confirm .ant-modal{
      margin-top: 200px;
  }
      .ant-modal-body{
        padding: 0!important;
      } .ant-modal-confirm-body-wrapper{
        .ant-modal-confirm-title{
          height: 50px;
          line-height: 50px;
          padding: 0 10px;
          border-bottom: 1px solid #eee;
        }
        .ant-modal-confirm-content{
          border-bottom: 1px solid #eee;
        }
        .ant-modal-confirm-btns{
          margin-top: 14px;
          margin-bottom: 12px;
          margin-right: 10px;
        }
    }
}
</style>
